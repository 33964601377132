import { FabAIObservables } from '@copilot/mfa-communication';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { constructRouteChatAI, MESSAGE_ID_CHATBOX_KEY } from 'utils/fabAI';

const AppChatboxController = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Subscribe to the data from the chatbot
    FabAIObservables.RxJsService.receiveDataFromChatbot().subscribe({
      next: data => {
        console.log(
          'Init chatbox inventory',
          FabAIObservables.RxJsService,
          data
        );
        if (data) {
          const { routeDetails, entities, actionStep, messageId } =
            data?.data?.[0];

          const { suggestionEventId } = data;

          console.log('Received data from chatbot:', data);

          if (
            isEqual(sessionStorage.getItem(MESSAGE_ID_CHATBOX_KEY), messageId)
          )
            return;

          const path = constructRouteChatAI({ routeDetails, actionStep });

          if (path) {
            sessionStorage.setItem(MESSAGE_ID_CHATBOX_KEY, messageId);
            navigate(path, { state: { ...entities, suggestionEventId } });
          }
        }
      },
    });
  }, []);

  return null;
};

export default AppChatboxController;

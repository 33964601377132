import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { APP } from 'data';

const InventoryLanding = React.lazy(
  () => import('../features/InventoryLanding')
);
const InventoryDetails = React.lazy(
  () => import('../features/InventoryDetails')
);

import GenericPageLoader from '../components/GenearicPageLoader';
import AppChatboxController from './AppChatboxController';

const NetworkLanding = React.lazy(() => import('../features/NetworkLanding'));
const NetworkDetails = React.lazy(() => import('../features/NetworkDetails'));

const LocationLanding = React.lazy(() => import('../features/LocationLanding'));
const LocationDetails = React.lazy(() => import('../features/LocationDetails'));

const ActivityLogLanding = React.lazy(() => import('../features/ActivityLogs'));

const DS4AppRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<GenericPageLoader />}>
        <Routes>
          <Route
            element={<InventoryLanding />}
            path={`/${APP}/manage-${APP}`}
          />
          <Route
            path={`/${APP}`}
            element={<Navigate to={`/${APP}/manage-${APP}`} />}
          />
          <Route
            element={<InventoryDetails />}
            path={`/${APP}/manage-${APP}/:sku`}
          />

          <Route element={<NetworkLanding />} path={`/${APP}/network`} />
          <Route element={<NetworkDetails />} path={`/${APP}/network/create`} />
          <Route
            element={<NetworkDetails />}
            path={`/${APP}/network/edit/:networkId`}
          />

          <Route element={<LocationLanding />} path={`/${APP}/location`} />
          <Route
            element={<LocationDetails />}
            path={`/${APP}/location/create`}
          />
          <Route
            element={<LocationDetails />}
            path={`/${APP}/location/edit/:locationNumber`}
          />
          <Route
            element={<Navigate to={`/${APP}/activity-log`} />}
            path={`/${APP}/history`}
          />
          <Route
            element={<ActivityLogLanding />}
            path={`/${APP}/activity-log`}
          />
        </Routes>
      </Suspense>
      <AppChatboxController />
    </BrowserRouter>
  );
};

export default DS4AppRoutes;
